import React, { useState, useContext } from 'react'
import { navigate } from 'gatsby'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { context } from './index'
import {
  loginLayoutStyles,
  LoginIllustration,
  LoginLogo,
} from '../shared/elements'
import { TextField, Checkbox } from '../shared/fields'
import { useApiLoad } from '../shared/hooks'
import { login, getContractCode } from '../../services/auth'
import * as API from '../../services/api'

const Container = styled.div`
  ${loginLayoutStyles}

  margin: -42px -24px;
`

export default function SignupForm(props) {
  const { codeChecked, contract, initialEmail } = useContext(context)

  const [accessChecked, setAccessChecked] = useState(false)
  useApiLoad(
    '/signup/auth',
    codeChecked && accessChecked === false,
    (response) => {
      setAccessChecked(true)
      if (!response?.data?.success) {
        navigate('/')
      }
    }
  )

  const [isSubmitting, setSubmitting] = useState(false)
  const [error, setError] = useState('')

  const form = useForm({
    defaultValues: {
      info: {
        firstName: '',
        lastName: '',
        termsAccepted: false,
      },
      email: initialEmail || '',
      password: '',
    },
  })

  const onSubmit = async (values) => {
    values.code = getContractCode()
    values.name = `${values.info.firstName} ${values.info.lastName}`

    setError('')
    setSubmitting(true)

    API.postRequest('/advertiser/signup', { advertiser: values })
      .then(async (response) => {
        if (response?.data?.success) {
          const loggedIn = await login(values)
          setSubmitting(false)

          if (loggedIn) {
            navigate('/')
          } else {
            setError('Incorred email or password')
          }
        } else {
          setSubmitting(false)
          setError(response.message)
          return
        }
      })
      .catch((error) => {
        setSubmitting(false)
        setError(error.message)
      })
  }

  return (
    <Container>
      <LoginIllustration
        head="Join minetilbud."
        body="Minetilbud matches you with prime publishers and makes sure all traffic is tracked more precise than any other affiliate agency. We also make sure your partners are awarded with the correct commission. So all you have to do is sit back and enjoy all the enhanced traffic for your shop."
      />
      <div className="form-container">
        {codeChecked && accessChecked && (
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <LoginLogo />
            <h3>Welcome.</h3>
            <div className="row mt-4">
              <div className="col-md-10">
                <p>
                  You have been invited to connect with{' '}
                  {contract?.publisher?.name || '...'}
                  <br />
                  Get started by creating a user and don’t worry, you can always
                  create more users later.
                </p>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-sm-6">
                <TextField
                  form={form}
                  validation={{ required: true }}
                  name="info.firstName"
                  label="First name"
                />
              </div>
              <div className="col-sm-6">
                <TextField
                  form={form}
                  validation={{ required: true }}
                  name="info.lastName"
                  label="Last name"
                />
              </div>
            </div>

            <TextField
              form={form}
              validation={{ required: true }}
              type="email"
              name="email"
              label="Email address"
            />

            <TextField
              form={form}
              validation={{ required: true }}
              type="password"
              name="password"
              label="Password"
            />

            <Checkbox
              form={form}
              validation={{ required: true }}
              name="info.termsAccepted"
              label="Creating an account means you're okay with our <a href='https://heylink.com/terms.html' target='_blank'>Terms of Service</a>, <a href='https://heylink.com/privacy.html' target='_blank'>Privacy Policy</a>, and our default Notification Settings."
            />

            {error && <div className="text-danger mb-3">{error}</div>}

            <div className="actions mt-3">
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-primary"
              >
                Let's go, log in
              </button>
            </div>
          </form>
        )}
      </div>
    </Container>
  )
}
